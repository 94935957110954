<template>
    <div class="project-info m-rounded-12 p-4">
        <div class="d-flex justify-content-between">
            <h2 @click="test">Project Description</h2>
        </div>
        <hr />
        <p>
           {{ !description ? "No description available" : description }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        description: {
            type: String,
            default: ''
        }
    },
    methods: {
        test() {
            console.log(this.description)
        }
    }
};
</script>

<style lang="scss" scoped>
.project-info {
    background-color: $body-bg;
    width: 50%;
    overflow-y: auto;

    h2 {
        color: $black-tint-3;
        font-size: 1.5rem;
        line-height: 2.625rem;
        font-weight: 600;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}
</style>
  