<template>
  <div class="project-info m-rounded-12">
    <div class="d-flex justify-content-between">
      <h2>Project Information</h2>
    </div>
    <hr />
    <div>
      <b-row class="mb-3">
        <b-col sm="5">
          <strong>Start Date</strong>
        </b-col>
        <b-col>
          {{ project.start_date | capitalize }}
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col sm="5">
          <strong>End date</strong>
        </b-col>
        <b-col>
          {{ project.end_date ? project.end_date : '-' }}
        </b-col>
      </b-row>
      <b-row class="mb-">
        <b-col sm="5">
          <strong>Status</strong>
        </b-col>
        <b-col>
          {{ project.status_readable| capitalize }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        project: {
            type: Object,
            default: () => { },
            required: true,
        },
        status: {
            type: Object,
            default: () => {}
        }
    },
};
</script>
<style lang="scss" scoped>
.project-info {
    background-color: $body-bg;
    width: 50%;

    h2 {
        color: $black-tint-3;
        font-size: 1.5rem;
        line-height: 2.625rem;
        font-weight: 600;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}
</style>
  