<template>
  <div class="details px-4 py-3">
    <div class="p-4">
      <div class="d-flex justify-content-between">
        <h2 class="details__title">{{ project ? project.name : 'Project X' }}</h2>
        <b-button variant="primary" class="btn py-0 mr-2" @click="addEmployee()">
          Request Talent
        </b-button>
      </div>
      <hr class="horizontal_rule mb-5">

      <router-link class="mb-3 d-block text-decoration-none m-fs-12" to="/dashboard/projects">
        <ArrowLeftIcon class="mr-2" /> Back
      </router-link>

      <ProjectDescription :description="project.description" class="w-100 mb-5" />

      <div class="d-flex justify-content-between">
        <ProjectInfo :project="project" :status="status" class="p-3 mr-3" />
        <ProjectTalent :talents="project.employees" :no-of-employees="project.no_of_employees" class="p-3 ml-3" />
      </div>
    </div>
    <RequestTalentModal :project="project" :hiring-type="hiring_type" :processing="processing" @submit="requestTalent" />
  </div>
</template>

<script>
import ProjectDescription from '@/components/projects/ProjectDescription.vue';
import ProjectInfo from '@/components/projects/ProjectInfo.vue';
import ProjectTalent from '@/components/projects/ProjectTalent.vue';
import ArrowLeftIcon from "../../components/svg/ArrowLeftIcon.vue";
import experinceData from '@/data/experience.json';
import skillData from '@/data/skills.json';
import RequestTalentModal from "@/components/employee/RequestTalentModal.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ProjectDescription,
    ProjectInfo,
    ProjectTalent,
    RequestTalentModal,
    ArrowLeftIcon
  },
  data() {
    return {
      payload: {},
      processing: false,
      slides: skillData.slides,
      experience: experinceData.experience,
      status: {
        1: 'Pending',
        2: 'Active',
        3: 'Paused',
        4: 'Ended'
      },
      hiring_type: {
        "Full time": 1,
        "Part time": 0
      }
    };
  },
  computed: {
    ...mapState({
      project: (state) => state.projects.project
    })
  },
  created() {
    this.fetchProject(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      fetchProject: "projects/fetchProject"
    }),
    addEmployee() {
      this.$bvModal.show('request_talent')
    },
    requestTalent(form) {
      this.processing = true
      this.payload = form
      this.payload.project = this.project

      this.$store
        .dispatch("employees/requestTalent", this.payload)
        .then(() => {
          this.$bvModal.hide('request_talent')
        })
        .catch((err) => {
          let data = err?.response;
          if (data?.status === 422) {
            this.errors = this.getErrors(data.data.errors);
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.details {
  h2 {
    color: $black-tint-3;
    font-size: 1.5rem;
    line-height: 2.625rem;
    font-weight: 600;
  }

  &__title {
    text-transform: uppercase;
  }
}
</style>
