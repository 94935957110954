<template>
  <div class="talent-box m-rounded-12 d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Employees</h2>
      <span>{{ noOfEmployees }}</span>
    </div>

    <hr class="w-100" />

    <div class="talent-box__talent">
      <div v-for="(talent, index) in talents" :key="index" class="d-flex justify-content-between">
        <span class="mr-4 d-flex align-items-center w-100">
          <TextAvatar :initials="talent.name.substr(0, 1).toUpperCase()" class="mr-2" />
          <span class="mr-auto">{{ talent.name }}</span>
        </span>

        <!-- <span class="w-100 d-flex align-items-center">{{ talent.role }}</span> -->

        <span role="button" class="w-100 text-right" @click="moreInfo"> <MoreInfoIcon /> </span>
      </div>
    </div>
  </div>
</template>
<script>
import TextAvatar from '../TextAvatar.vue';
import MoreInfoIcon from "@/components/svg/MoreInfoIcon.vue";
export default {
    components: {
        TextAvatar,
        MoreInfoIcon,
    },
    props: {
        talents: {
            type: Array,
            default: () => [],
            required: true,
        },
        noOfEmployees: {
            type: Number,
            default: 0
        }
    },
    methods: {
        moreInfo() {
            this.$router.push('employees/details')
        },
    }
};
</script>
<style lang="scss" talented>
.talent-box {
    background-color: $body-bg;
    width: 50%;
    // max-height: 620px;

    h2 {
        color: $black-tint-3;
        font-size: 1.5rem;
        line-height: 2.625rem;
        font-weight: 600;
    }

    &__talent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80%;
        overflow: auto;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}
</style>
